import React from "react";
import styled from "styled-components";

function CombinedLogos({
  title = "Atlanta United FC Logo and American Family Insurance Logo",
  titleId = "combined-logos-svg",
  ...props
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={172}
      height={66.624}
      viewBox='0 0 172 66.624'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g data-name='Group 27'>
        <g data-name='Group 19'>
          <g data-name='Group 18' fill='currentColor'>
            <path
              data-name='Path 35'
              d='M27.92 53.72a21.031 21.031 0 0010.41 0v-4.527H27.92z'
            />
            <path
              data-name='Path 36'
              d='M38.33 13.28a21.03 21.03 0 00-10.41 0v3.42h10.41z'
            />
            <path data-name='Path 37' d='M30.757 39.34h4.735l-2.368-7.021z' />
            <path
              data-name='Path 38'
              d='M47.145 18.036v25l1.492 4.426a20.873 20.873 0 00-1.492-29.429z'
            />
            <path
              data-name='Path 39'
              d='M19.104 18.036a20.872 20.872 0 00-1.492 29.429l1.492-4.426z'
            />
            <path
              data-name='Path 40'
              d='M40.592 17.501h-7.467l9.193 26.771 2.288 6.662a20.979 20.979 0 003.411-2.808l-9.155-27.15z'
            />
            <path
              data-name='Path 41'
              d='M29.641 40.141l-5.71 4.132h18.385l-5.71-4.132z'
            />
            <path
              data-name='Path 42'
              d='M25.658 17.501l1.732 3.474-9.156 27.15a20.975 20.975 0 003.411 2.808l2.287-6.662L33.125 17.5z'
            />
            <path
              data-name='Path 43'
              d='M33.125.375A33.125 33.125 0 1066.25 33.5 33.125 33.125 0 0033.125.375zm0 65.435a32.311 32.311 0 1132.311-32.311A32.311 32.311 0 0133.125 65.81z'
            />
            <path
              data-name='Path 44'
              d='M33.124 11.004a22.5 22.5 0 1022.5 22.5 22.5 22.5 0 00-22.5-22.5zm0 44.179a21.684 21.684 0 1121.684-21.684 21.684 21.684 0 01-21.684 21.684z'
            />
            <path
              data-name='Path 45'
              d='M31.857 9.166l.258-.921q1-.037 1.992.007l.256.922q.7.038 1.4.118-.817-2.8-1.638-5.551l.184-.586q-1.177-.062-2.355-.007l.18.587q-.839 2.742-1.674 5.54.696-.075 1.397-.109zm1.268-4.5q.319 1.155.637 2.319-.645-.019-1.292 0 .327-1.162.654-2.315z'
            />
            <path
              data-name='Path 46'
              d='M49.735 15.628l.841-.457q.725.684 1.39 1.426l-.481.826q.463.527.895 1.08 1.429-2.547 2.815-5.056l.548-.28q-.66-.746-1.38-1.437-2.819 1.462-5.685 2.975.542.447 1.057.923zm4.1-2.244q-.6 1.038-1.207 2.081-.439-.475-.9-.925z'
            />
            <path
              data-name='Path 47'
              d='M46.251 12.948q1.232-2.04 2.462-4.081.552.348 1.09.718l.959-.851a33.115 33.115 0 00-3.827-2.324l-.646 1.07q.664.344 1.313.717l-2.462 4.081q.56.323 1.111.67z'
            />
            <path
              data-name='Path 48'
              d='M39.243 9.875l1.006-3.4q.371 1.926.723 3.917.591.2 1.172.437l1.71-5.767q-.6-.243-1.216-.452l-1.006 3.4q-.352-1.989-.724-3.913-.694-.18-1.4-.319c0 .2.006.4.01.607l-1.541 5.2q.638.129 1.266.29z'
            />
            <path
              data-name='Path 49'
              d='M13.984 18.504q.433-.553.895-1.08l-.482-.826q.666-.742 1.391-1.426l.841.457q.515-.476 1.057-.922-2.866-1.514-5.685-2.975-.719.689-1.38 1.436l.547.279q1.388 2.509 2.816 5.057zm.657-3.963q-.464.451-.9.925-.607-1.044-1.207-2.081l2.108 1.156z'
            />
            <path
              data-name='Path 50'
              d='M17.654 8.866l2.462 4.081q.548-.346 1.11-.67l-2.463-4.081q.648-.374 1.313-.717l-.646-1.07a33.151 33.151 0 00-3.827 2.324l.96.851q.538-.369 1.091-.718z'
            />
            <path
              data-name='Path 51'
              d='M27.989 9.684l.03-1.283q-1.263.267-2.5.651L24.208 4.47q-.619.193-1.229.415l1.655 5.783a27.574 27.574 0 013.355-.984z'
            />
            <path
              data-name='Path 52'
              d='M20.142 60.905q.586.277 1.18.534l2.482-5.48q-.594-.258-1.18-.535z'
            />
            <path
              data-name='Path 53'
              d='M25.317 57.843q.712.232 1.437.419l-1.076 4.643q.628.162 1.263.293l1.076-4.643q.733.15 1.475.256l.282-1.217a24.74 24.74 0 01-4.175-.968q-.141.617-.282 1.217z'
            />
            <path
              data-name='Path 54'
              d='M33.064 61.397q1.015.006 2.029-.071l-.025-1.25q-1.013.078-2.029.071l-.021-1.058a24.635 24.635 0 002.578-.12l-.406-1.214a24.756 24.756 0 01-3.494.043l.121 6.014a24.662 24.662 0 003.494-.044l.356-1.283a24.719 24.719 0 01-2.578.119l-.025-1.207z'
            />
            <path
              data-name='Path 55'
              d='M42.222 57.743a1.461 1.461 0 00-1.88-.989 25.434 25.434 0 01-2.755.68l.339.539 1.46 5.22a25.338 25.338 0 002.576-.646 1.637 1.637 0 001.048-1.988l-.788-2.816zm-.641 3.615q-.628.192-1.266.351l-.947-3.386q.638-.16 1.265-.351a.287.287 0 01.363.2l.788 2.817a.305.305 0 01-.203.369z'
            />
            <path
              data-name='Path 56'
              d='M19.35 53.521l-2.114 2.841q.314-1.934.666-3.921-.487-.392-.955-.807l-3.591 4.826q.485.431.992.836l2.114-2.842q-.352 1.985-.664 3.918.515.355 1.048.684l3.591-4.826q-.553-.343-1.087-.709z'
            />
            <path
              data-name='Path 57'
              d='M14.474 49.045l-3.617 2.8a.307.307 0 01-.423-.047q-.322-.394-.626-.8a.3.3 0 01.058-.419l3.617-2.8q-.435-.6-.831-1.224l-.372.518-3.147 2.438a1.63 1.63 0 00-.34 2.218q.331.446.683.875a1.645 1.645 0 002.242.217l3.617-2.8q-.447-.479-.861-.976z'
            />
            <path
              data-name='Path 58'
              d='M50.887 53.411q-.76.675-1.57 1.287l-.656-.83q1.029-.779 1.978-1.658l-1.057-.722a25.958 25.958 0 01-2.9 2.258l.519.364 3.362 4.253q.536-.365 1.054-.756l-1.525-1.929q.81-.612 1.57-1.287z'
            />
            <path
              data-name='Path 59'
              d='M57.462 49.231l-.39-.3q-.358.54-.737 1.066l.39.3a.3.3 0 01.061.418q-.3.41-.614.81a.3.3 0 01-.419.054l-2.5-1.893a.289.289 0 01-.053-.411c.209-.266.413-.537.614-.81a.289.289 0 01.409-.061l.27.2q.379-.525.738-1.066l-.27-.2a1.487 1.487 0 00-2.116.335q-.327.447-.67.884a1.487 1.487 0 00.25 2.128l2.5 1.893a1.61 1.61 0 002.214-.261q.342-.436.67-.884a1.61 1.61 0 00-.347-2.202z'
            />
            <path
              data-name='Path 60'
              d='M6.133 33.504a26.863 26.863 0 014.355-14.7l-.44-.833a27.842 27.842 0 00-1.849 27.895l.739-.368a26.878 26.878 0 01-2.805-11.994z'
            />
            <path
              data-name='Path 61'
              d='M57.311 45.489l.739.368a27.844 27.844 0 00-1.849-27.895l-.44.833a27.015 27.015 0 011.55 26.694z'
            />
          </g>
        </g>
        <path
          data-name='Line 5'
          fill='none'
          stroke='currentColor'
          strokeMiterlimit={10}
          strokeWidth={2}
          d='M80.725 0v66.624'
        />
        <g data-name='Group 26'>
          <g data-name='Group 25'>
            <g data-name='Group 24'>
              <path
                data-name='Path 62'
                d='M170.674 40.246a1.322 1.322 0 11-1.332 1.315 1.315 1.315 0 011.332-1.315zm0 2.449a1.128 1.128 0 10-1.091-1.134 1.09 1.09 0 001.091 1.134zm-.515-1.9h.6c.368 0 .54.144.54.441a.4.4 0 01-.406.422l.443.682h-.259l-.42-.665h-.255v.665h-.24zm.24.685h.251c.212 0 .407-.011.407-.255 0-.205-.177-.237-.339-.237h-.319v.492'
                fill='currentColor'
              />
              <g data-name='Group 23'>
                <path
                  data-name='Path 63'
                  d='M169.622 25.635H138.79l-20.726-5.182-22.084 6.005.563 2.1 21.522-5.849 20.435 5.109h31.122z'
                  fill='currentColor'
                />
                <g data-name='Group 22'>
                  <path
                    data-name='Path 64'
                    d='M113.925 31.315h-1.993v.894h1.584v1.143h-1.584v1.051h1.985v1.142h-3.422v-5.369h3.43z'
                    fill='currentColor'
                  />
                  <path
                    data-name='Rectangle 139'
                    fill='currentColor'
                    d='M120.418 30.173h1.437v5.369h-1.437z'
                  />
                  <path
                    data-name='Rectangle 140'
                    fill='currentColor'
                    d='M157.702 30.173h1.436v5.369h-1.436z'
                  />
                  <path
                    data-name='Path 65'
                    d='M142.755 35.542h-1.432v-5.369h3.43v1.142h-1.993v.894h1.584v1.143h-1.584z'
                    fill='currentColor'
                  />
                  <path
                    data-name='Path 66'
                    d='M160.578 35.542H164V34.18h-1.985v-4.007h-1.437z'
                    fill='currentColor'
                  />
                  <path
                    data-name='Path 67'
                    d='M136.077 30.173v2.915l-1.443-2.915h-1.613v5.369h1.4v-2.915l1.443 2.915h1.614v-5.369z'
                    fill='currentColor'
                  />
                  <path
                    data-name='Path 68'
                    d='M100.963 30.173l1.813 5.369h-1.474l-.36-1.066h-1.494l-.36 1.066h-1.363l1.813-5.369zm-.365 3.162l-.4-1.194-.4 1.194z'
                    fill='currentColor'
                  />
                  <path
                    data-name='Path 69'
                    d='M130.544 30.173l1.814 5.369h-1.474l-.36-1.066h-1.494l-.36 1.066h-1.363l1.813-5.369zm-.365 3.162l-.4-1.194-.4 1.194z'
                    fill='currentColor'
                  />
                  <path
                    data-name='Path 70'
                    d='M148.1 30.173l1.813 5.369h-1.474l-.36-1.066h-1.495l-.36 1.066h-1.364l1.813-5.369zm-.365 3.162l-.4-1.194-.4 1.194z'
                    fill='currentColor'
                  />
                  <path
                    data-name='Path 71'
                    d='M117.242 33.778l.662 1.764h1.384l-.821-2.186a1.519 1.519 0 00.821-1.489 1.653 1.653 0 00-.57-1.349 1.879 1.879 0 00-1.2-.345h-2.37v5.369h1.437v-1.764zm-.659-1.326v-1.078h.587a.777.777 0 01.408.074.544.544 0 01.224.461c0 .485-.374.543-.686.543z'
                    fill='currentColor'
                  />
                  <path
                    data-name='Path 72'
                    d='M166.984 30.173l-1.036 1.968-1.036-1.968h-1.41l1.728 3.279v2.087h1.437v-2.087l1.728-3.283z'
                    fill='currentColor'
                  />
                  <g data-name='Group 20'>
                    <path
                      data-name='Path 73'
                      d='M107.851 35.542v-2.425l-.95 2.425h-1.037l-.95-2.425v2.425h-1.393v-5.369h1.6l1.262 3.221 1.262-3.221h1.6v5.369z'
                      fill='currentColor'
                    />
                  </g>
                  <g data-name='Group 21'>
                    <path
                      data-name='Path 74'
                      d='M154.869 35.542v-2.426l-.95 2.426h-1.037l-.95-2.426v2.426h-1.393v-5.369h1.6l1.262 3.221 1.262-3.221h1.6v5.369z'
                      fill='currentColor'
                    />
                  </g>
                  <path
                    data-name='Path 75'
                    d='M126.254 31.688a1.083 1.083 0 00-.764-.339 1.542 1.542 0 000 3.018 1.085 1.085 0 00.765-.341l.751.916a2.287 2.287 0 01-1.663.749 2.861 2.861 0 010-5.666 2.286 2.286 0 011.663.749z'
                    fill='currentColor'
                  />
                </g>
                <path
                  data-name='Path 76'
                  d='M97.725 36.62h70.638v6.27H97.725zm34.406 3.718l.672 1.115h1.417l-.833-1.382c.557-.192.833-.474.833-.941a.929.929 0 00-.578-.854 2.8 2.8 0 00-1.219-.22h-2.684v3.4h1.437v-1.115zm-.114-.839c.317 0 .7-.036.7-.344a.326.326 0 00-.227-.291 1.216 1.216 0 00-.413-.047h-.9v.681zm-28.5 1.954h1.437v-3.4h-1.435zm35.5-2.37h-.01l-.43 1.152h.87zm-2.445 2.37h1.534l.154-.391h1.5l.153.391h1.535l-1.556-3.4h-1.77zm17.665-1.323a.764.764 0 01-.844.585c-.527 0-.89-.271-.89-.961a.851.851 0 01.962-.961.7.7 0 01.772.595h1.627c-.19-1.347-1.53-1.451-2.4-1.451s-2.5.057-2.5 1.818 1.622 1.818 2.5 1.818a3.107 3.107 0 001.785-.385 1.569 1.569 0 00.614-1.057zm4.254 1.323h4.073v-.828h-2.631v-.457h2.457v-.827h-2.457v-.457h2.631v-.828h-4.073zm-50.444 0h1.38v-1.682h.011l1.534 1.683h1.581v-3.4h-1.382v1.664h-.014l-1.463-1.664h-1.648zm35.9 0h1.38v-1.682h.011l1.534 1.683h1.581v-3.4h-1.381v1.664h-.015l-1.463-1.664h-1.647zm-21.619-1.29c0 1.08.848 1.408 2.332 1.408s2.331-.328 2.331-1.408v-2.108h-1.442v2.056c0 .438-.418.576-.889.576s-.889-.138-.889-.576v-2.056h-1.443zm-4.439.076c.194.029.261.128.261.228 0 .162-.123.276-.573.276a1.265 1.265 0 01-.491-.071.259.259 0 01-.153-.243h-1.74c.005 1 1.218 1.142 2.328 1.142 1.622 0 2.348-.39 2.348-1.218 0-.575-.45-.89-1.177-.99l-1.724-.238c-.143-.019-.189-.081-.189-.176 0-.143.148-.243.578-.243a1.226 1.226 0 01.45.072.283.283 0 01.22.271h1.678c-.051-.718-.721-1.113-2.348-1.113-1.187 0-2.112.238-2.112 1.118 0 .476.291.857 1 .956z'
                  fill='currentColor'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

const StyledCombinedLogos = styled(CombinedLogos)`
  color: ${({ $color = "white", theme }) =>
    theme.colors[$color] ? theme.colors[$color] : $color};
`;

export default StyledCombinedLogos;
