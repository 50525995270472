import React from "react";
import Header from "~components/ui/header";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";
import Container from "~components/ui/container";
import GlobalStyles from "~styles/GlobalStyles";
import theme from "~styles/theme";
import CombinedLogos from "../icons/combined-logos-united";

const Layout = ({ children }) => (
  <ThemeProvider theme={theme("falcons")}>
    <GlobalStyles />

    <StyledLayout id='page-outer-wrapper'>
      <Header fixed={true} menuItems={[]} Logo={CombinedLogos} />
      <StyledContainer>{children}</StyledContainer>
    </StyledLayout>
  </ThemeProvider>
);

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
`;

const StyledContainer = styled(Container)``;

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
