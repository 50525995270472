import React from "react";
import Layout from "~components/layouts/index";

// markup
const PrivacyPolicy = () => {
  return (
    <Layout>
      <main>
        <title>Privacy Policy</title>
        <p>LEGAL STUFF</p>
      </main>
    </Layout>
  );
};

export default PrivacyPolicy;
